import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../Literaryclub2023/components/Sidebar2024';
import Archive2023 from '../Literaryclub2023/components/Archive2023';
import LiterarySidebar from '../Literaryclub2023/components/LiterarySidebar';

const HindiCCA3to52024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Hindicca3to5/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Hindicca3to5/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Hindicca3to5/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Hindicca3to5/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Hindicca3to5/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/association/LitreryClub/Hindicca3to5/6.webp`;

    const photos = [

        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },

    ];

    return (
        <Main>

            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                    Hindi CCA 3 TO 5
                                </Typography>
                                <br />
                                <Typography variant='h7' align='justify' >
                                    Class 3:  Mythological Characters
                                    <br />
                                    Class 4: Patriotic Songs
                                    <br />
                                    Class 5: Humorous Poems
                                </Typography>

                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 3 to 5     Date:  7 December 2025

                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "Poetry comes alive to me through recitation!"
                                <br />
                            </Typography> */}
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The Hindi CCA Activity for Classes 3, 4 and 5 was held on Tuesday, 07 January 2025, which
                                    exhibited creativity, artistic and speaking skills of the participants. It was an individual
                                    performance that provided the participants with an opportunity to showcase their oratory
                                    skills with composure and confidence.
                                    <br />
                                    Class 3 students enacted their favourite mythological characters and provided valuable insights
                                    about the lives of mythological characters. Students of Class 4 enthusiastically sang Patriotic
                                    Songs with great passion and intensity which brought out patriotic fervour among the
                                    audience and Class 5 students presented Humorous Poems which were highly entertaining.
                                    <br />
                                    The students combined their creative imagination and oratory skills to put up a wonderful
                                    presentation. The above activities proved to be an enriching learning experience. It enhanced
                                    their confidence, presentation and communication skills.
                                    <br />
                                </Typography>
                                {/* <Typography
                                    variant={'h6'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    The journey of self-discovery begins with the truth within.
                                    <br />
                                </Typography> */}
                            </Box>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 500}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <LiterarySidebar />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default HindiCCA3to52024;